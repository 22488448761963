import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateSelector.scss";

interface DateSelectorProps {
  initialMonth?: Date;
  onDateChange: (date: Date) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({
  initialMonth = null,
  onDateChange,
}) => {
  const [date, setDate] = useState(initialMonth ?? new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const calendarRef = useRef(null); // Reference to the calendar popup

  const changeMonth = (months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + months);
    setDate(newDate);
    onDateChange(newDate);
  };

  const handleDateChange = (selectedDate: Date) => {
    setDate(selectedDate);
    setIsCalendarOpen(false); // Close the calendar when a month is selected
    onDateChange(selectedDate);
  };

  // Close calendar if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !(calendarRef.current as any).contains(event.target)) {
        setIsCalendarOpen(false); // Close the calendar
      }
    };

    if (isCalendarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCalendarOpen]);

  return (
    <div className="date-selector">
      <button
        onClick={() => changeMonth(-1)}
        className="date-button prev"
      >
        <i className="fal fa-chevron-left"></i>
      </button>

      <input
        type="text"
        value={format(date, "MMM yyyy")}
        className="date-input"
        readOnly
        onClick={() => setIsCalendarOpen(true)} // Open calendar on input click
      />

      <button
        onClick={() => changeMonth(1)}
        className="date-button next"
      >
        <i className="fal fa-chevron-right"></i>
      </button>

      {isCalendarOpen && (
        <div className="calendar-popup" ref={calendarRef}>
          <DatePicker
            selected={date}
            onMonthChange={handleDateChange}
            dateFormat="MMM yyyy"
            showMonthYearPicker
            inline
          />
        </div>
      )}
    </div>
  );
};

export default DateSelector;
