import React from "react";
import "./App.scss";
import Sidebar from "./components/Sidebar/Sidebar";
import BudgetView from "./components/BudgetView/BudgetView";
import { BudgetProvider } from "./contexts/BudgetContext";

const App: React.FC = () => {
  return (
    <div className="app w-full flex h-svh max-h-svh bg-primary">
      <Sidebar />
      <BudgetProvider>
        <BudgetView />
      </BudgetProvider>
    </div>
  );
};

export default App;
