// Currency.tsx
import React from 'react';

interface CurrencyProps {
  amount: number;
  currency: 'GBP';
}

const formatCurrency = (amount: number, currency: 'GBP'): string => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

const Currency: React.FC<CurrencyProps> = ({ amount, currency }) => {
  return <span>{formatCurrency(amount, currency)}</span>;
};

export default Currency;
