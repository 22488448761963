import React, { useState } from "react";
import "./BudgetTable.scss";
import EditCateogryItemModal from "../EditCateogryItemModal/EditCateogryItemModal";
import Button from "../Button/Button";
import { useBudget } from "../../contexts/BudgetContext";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import Category from "../../models/Category";
import EditableTextInput from "../EditableTextInput/EditableTextInput";
import Currency from "../../utilities/Currency";

interface BudgetTableProps {}

const BudgetTable: React.FC<BudgetTableProps> = () => {
  const { budget, addItem } = useBudget();

  // State for modal visibility and the current item being edited
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<{
    name: string;
    assigned: number;
    activity: number;
    available: number;
  } | null>(null);

  // State for tracking checked categories and items
  const [checkedCategories, setCheckedCategories] = useState<{
    [key: number]: boolean;
  }>({});
  const [checkedItems, setCheckedItems] = useState<{
    [key: number]: { [key: number]: boolean };
  }>({});

  /**
   * Calculate the total amount for a specific field in a category.
   * @param {Category} category - The category to calculate totals for.
   * @param {"assigned" | "activity" | "available"} field - The field to sum up.
   * @returns {number} - The total amount.
   */
  const calculateTotal = (
    category: Category,
    field: "assigned" | "activity" | "available"
  ) => {
    return category.items.reduce((total, item) => total + item[field], 0);
  };

  /**
   * Handle checking/unchecking a category and update associated items.
   * @param {number} categoryId - The ID of the category.
   */
  const handleCategoryCheck = (categoryId: number) => {
    setCheckedCategories((prevCheckedCategories) => {
      const isChecked = !prevCheckedCategories[categoryId];

      // Update the category's checked state
      const newCheckedCategories = {
        ...prevCheckedCategories,
        [categoryId]: isChecked,
      };

      // Update all items under this category
      const categoryItems =
        budget.find((category) => category.id === categoryId)?.items || [];

      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        [categoryId]: categoryItems.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: isChecked,
          }),
          {}
        ),
      }));

      return newCheckedCategories;
    });
  };

  /**
   * Handle checking/unchecking an individual item within a category.
   * @param {number} categoryId - The ID of the category.
   * @param {number} itemId - The ID of the item.
   */
  const handleItemCheck = (categoryId: number, itemId: number) => {
    setCheckedItems((prevCheckedItems) => {
      const categoryItems = prevCheckedItems[categoryId] || {};
      const isChecked = !categoryItems[itemId];

      // Update the item's checked state
      const newCategoryItems = {
        ...categoryItems,
        [itemId]: isChecked,
      };

      // Determine if all items in the category are checked
      const allItemsChecked = Object.values(newCategoryItems).every(Boolean);

      // Update the category's checked state
      setCheckedCategories((prevCheckedCategories) => ({
        ...prevCheckedCategories,
        [categoryId]: allItemsChecked,
      }));

      return {
        ...prevCheckedItems,
        [categoryId]: newCategoryItems,
      };
    });
  };

  /**
   * Handle clicking on the edit button for an item.
   * @param {object} item - The item to edit.
   */
  const handleEditClick = (item: {
    name: string;
    assigned: number;
    activity: number;
    available: number;
  }) => {
    setCurrentItem(item);
    setIsModalOpen(true);
  };

  /**
   * Handle adding a new item to a category.
   * @param {number} categoryId - The ID of the category.
   */
  const handleAddClick = (categoryId: number) => {
    const newItem = {
      name: "New Item",
      assigned: 100,
      activity: 0,
      available: 100,
    };
    addItem(categoryId, newItem);
  };

  /**
   * Render a category row.
   * @param {Category} category - The category to render.
   */
  const renderCategoryRow = (category: Category) => (
    <tr
      key={category.id}
      className="category"
      onClick={() => handleCategoryCheck(category.id)}
    >
      <td>
        <input
          type="checkbox"
          checked={checkedCategories[category.id] || false}
          onChange={() => handleCategoryCheck(category.id)}
        />
      </td>
      <td>
        <div className="flex space-x-2 items-center">
          <EditableTextInput
            text={category.name}
            onTextChange={(newText) => {
              console.log(newText);
            }}
          />
          <Button
            variant="outlined"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation(); // Prevent row click
              handleAddClick(category.id);
            }}
            iconLeft="fal fa-plus"
            small={true}
            className="add-button"
          />
        </div>
      </td>
      <td>
        <Currency
          amount={calculateTotal(category, "assigned")}
          currency="GBP"
        />
      </td>
      <td>
        <Currency
          amount={calculateTotal(category, "activity")}
          currency="GBP"
        />
      </td>
      <td>
        <Currency
          amount={calculateTotal(category, "available")}
          currency="GBP"
        />
      </td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
  );

  /**
   * Render an item row within a category.
   * @param {number} categoryId - The ID of the category.
   * @param {object} item - The item to render.
   */
  const renderItemRow = (
    categoryId: number,
    item: {
      id: number;
      name: string;
      assigned: number;
      activity: number;
      available: number;
    }
  ) => (
    <tr key={item.id} onClick={() => handleItemCheck(categoryId, item.id)}>
      <td>
        <input
          type="checkbox"
          checked={checkedItems[categoryId]?.[item.id] || false}
          onChange={() => handleItemCheck(categoryId, item.id)}
        />
      </td>
      <td>
        <EditableTextInput
          text={item.name}
          onTextChange={(newText) => {
            console.log(newText);
          }}
        />
      </td>
      <td>
        <Currency amount={item.assigned} currency="GBP" />
      </td>
      <td>
        <Currency amount={item.activity} currency="GBP" />
      </td>
      <td>
        <Currency amount={item.available} currency="GBP" />
      </td>
      <td>
        <ButtonGroup>
          <Button
            variant="outlined"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation(); // Prevent row click
              handleEditClick(item);
            }}
            iconLeft="fal fa-money-check-edit"
            small={true}
          />
          <Button
            variant="outlined"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation(); // Prevent row click
              // Handle delete logic here
            }}
            iconLeft="fal fa-trash-alt"
            small={true}
          />
        </ButtonGroup>
      </td>
      <td>&nbsp;</td>
    </tr>
  );

  return (
    <>
      <table className="budget-table table-auto w-full border-y divide-y-*">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Category</th>
            <th>Assigned</th>
            <th>Activity</th>
            <th>Available</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {budget.map((category) => (
            <React.Fragment key={category.id}>
              {renderCategoryRow(category)}
              {category.items.map((item) => renderItemRow(category.id, item))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {isModalOpen && currentItem && (
        <EditCateogryItemModal
          item={currentItem}
          onClose={() => setIsModalOpen(false)}
          onSave={(updatedItem) => {
            setIsModalOpen(false);
            // Handle saving logic here
          }}
        />
      )}
    </>
  );
};

export default BudgetTable;
