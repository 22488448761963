import "./Sidebar.scss";

const Sidebar: React.FC = () => {
  return (
    <>
      <aside
        className={`fixed inset-y-0 left-0 flex flex-col w-20 md:w-64 h-screen px-4 py-8 overflow-y-auto bg-white border-r dark:bg-gray-900 dark:border-gray-700 z-50 transition-all duration-300 transform lg:translate-x-0`}
      >
        <div className="mx-auto">
          <img className="w-auto h-6 sm:h-7" src="/logo.svg" alt="logo" />
        </div>

        <div className="flex flex-col items-center mt-6 -mx-2">
          <h4 className="mx-2 mt-2 font-medium text-gray-800 dark:text-gray-200 hidden md:block">
            John Doe
          </h4>
          <p className="mx-2 mt-1 text-sm font-medium text-gray-600 dark:text-gray-400 hidden md:block">
            john@example.com
          </p>
        </div>

        <div className="flex flex-col justify-between flex-1 mt-6">
          <nav>
            <div className="flex items-center px-4 py-2 text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-800 dark:text-gray-200 cursor-pointer">
              <i className="fal fa-piggy-bank w-5 h-5"></i>
              <span className="mx-4 font-medium hidden md:block">Budget</span>
            </div>

            <div className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700 cursor-pointer">
              <i className="fal fa-university w-5 h-5"></i>
              <span className="mx-4 font-medium hidden md:block">Accounts</span>
            </div>

            <div className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700 cursor-pointer">
              <i className="fal fa-chart-line w-5 h-5"></i>
              <span className="mx-4 font-medium hidden md:block">Reports</span>
            </div>

            <div className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700 cursor-pointer">
              <i className="fal fa-project-diagram w-5 h-5"></i>
              <span className="mx-4 font-medium hidden md:block">
                Automation
              </span>
            </div>

            <div className="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700 cursor-pointer">
              <i className="fal fa-cog w-5 h-5"></i>
              <span className="mx-4 font-medium hidden md:block">Settings</span>
            </div>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
