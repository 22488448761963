import React, { useState, useRef, useEffect } from "react";
import "./EditableTextInput.scss";

interface EditableTextInputProps {
  text: string;
  onTextChange: (newText: string) => void;
}

const EditableTextInput: React.FC<EditableTextInputProps> = ({
  text,
  onTextChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(text);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleDisplayClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (inputValue.trim() === "") {
      setInputValue(text);
    }
    onTextChange(inputValue);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setIsEditing(false);
      onTextChange(inputValue);
    }
  };

  return isEditing ? (
    <input
      ref={inputRef}
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      className="editable-input px-2 py-1"
    />
  ) : (
    <span className="editable-text cursor-pointer" onClick={handleDisplayClick}>
      {text || "Click to edit"}
    </span>
  );
};

export default EditableTextInput;
