import { useBudget } from "../../contexts/BudgetContext";
import BudgetHeader from "../BudgetHeader/BudgetHeader";
import BudgetTable from "../BudgetTable/BudgetTable";
import Toolbar from "../Toolbar/Toolbar";
import "./BudgetView.scss";

const BudgetView: React.FC = () => {
  const { budget } = useBudget();
  return (
    <div className="budget-view md:ml-64 ml-20 w-full">
      <div className="w-full">
        <BudgetHeader />
      </div>
      <div className="flex flex-col lg:flex-row space-y-0 space-x-0">
        <div className="w-full">
          <Toolbar />
          <BudgetTable />
        </div>
      </div>
    </div>
  );
};

export default BudgetView;
