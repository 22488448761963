import React, { ReactNode } from "react";

interface ButtonProps {
  iconLeft?: string;
  iconRight?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  variant?: "primary" | "secondary" | "danger" | "success" | "outlined";
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  iconLeft,
  iconRight,
  children,
  className = undefined,
  variant = "primary",
  disabled = false,
  loading = false,
  small = false,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled && !loading) {
      onClick?.(event); // Call the original onClick handler
    }
  };

  const baseClasses = `${
    className ? `${className} ` : ""
  }rounded-md font-semibold focus:outline-none focus:ring focus:ring-opacity-50 transition duration-300 ease-in-out inline-flex items-center justify-center`;

  let variantClasses = "";

  switch (variant) {
    case "primary":
      variantClasses =
        "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-300";
      break;
    case "secondary":
      variantClasses =
        "bg-gray-500 text-white hover:bg-gray-600 focus:ring-gray-300";
      break;
    case "danger":
      variantClasses =
        "bg-red-500 text-white hover:bg-red-600 focus:ring-red-300";
      break;
    case "success":
      variantClasses =
        "bg-green-500 text-white hover:bg-green-600 focus:ring-green-300";
      break;
    case "outlined":
      variantClasses =
        "border border-gray-300 text-gray-700 hover:bg-gray-100 focus:ring-gray-300 bg-transparent";
      break;
  }

  const smallClasses = small ? "px-3 py-2 text-sm" : "px-4 py-2 text-base";
  const disabledClasses =
    disabled || loading ? "opacity-50 cursor-not-allowed" : "";
  const iconSizeClasses = !small ? "w-4 h-4" : "";

  return (
    <button
      className={`${baseClasses} ${variantClasses} ${disabledClasses} ${smallClasses}`}
      onClick={handleClick} // Use the new handler that stops propagation
      disabled={disabled || loading}
    >
      {iconLeft && !loading && (
        <i className={`${iconLeft} ${iconSizeClasses} mr-1`}></i>
      )}

      {loading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      )}

      {children}

      {iconRight && !loading && (
        <i className={`${iconRight} ${iconSizeClasses} ml-1`}></i>
      )}
    </button>
  );
};

export default Button;
