import React from "react";
import "./ToBeAssigned.scss";
import Button from "../Button/Button";
import Currency from "./../../utilities/Currency";

interface ToBeAssignedProps {
  amount: number;
  onAssign: () => void;
}

const ToBeAssigned: React.FC<ToBeAssignedProps> = ({ amount, onAssign }) => {
  const colourClass =
    amount < 0 ? "bg-red-100" : amount === 0 ? "bg-gray-100" : "bg-green-100";
    const variant =
    amount < 0 ? "danger" : amount === 0 ? "primary" : "success";
  return (
    <div
      className={`${colourClass} max-w-72 min-w-56 mx-4 text-black rounded-lg p-4 flex justify-between items-center shadow-md`}
    >
      <div>
        <p className="text-xl font-semibold">
          <Currency amount={amount} currency="GBP" />
        </p>
        <p className="text-sm text-gray-600">Ready to Assign</p>
      </div>

        <Button onClick={onAssign} variant={variant}>
          { amount === 0 ? 'Add Funds' : 'Assign' }
        </Button>
    </div>
  );
};

export default ToBeAssigned;
