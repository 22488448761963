import React, { createContext, useContext, useState } from "react";
import Item from "../models/Item";
import Category from "../models/Category";

interface BudgetContextProps {
  budget: Category[];
  addCategory: (newCategory: Omit<Category, "id">) => void;
  addItem: (categoryId: number, newItem: Omit<Item, "id">) => void;
}

const BudgetContext = createContext<BudgetContextProps | undefined>(undefined);

export const useBudget = () => {
  const context = useContext(BudgetContext);
  if (!context) {
    throw new Error("useBudget must be used within a BudgetProvider");
  }
  return context;
};

export const BudgetProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [budget, setBudget] = useState<Category[]>([
    {
      id: 1,
      name: "Bills",
      items: [
        {
          id: 1,
          name: "Mortgage",
          assigned: 1500,
          activity: 1500,
          available: 0,
        },
        { id: 2, name: "Electric", assigned: 100, activity: 80, available: 20 },
        { id: 3, name: "Water", assigned: 50, activity: 45, available: 5 },
        { id: 4, name: "Internet", assigned: 60, activity: 60, available: 0 },
        { id: 5, name: "Mobile", assigned: 70, activity: 70, available: 0 },
      ],
    },
    {
      id: 2,
      name: "Credit Card Payments",
      items: [
        { id: 6, name: "PayPal", assigned: 150, activity: 150, available: 0 },
      ],
    },
    {
      id: 3,
      name: "Frequent",
      items: [
        {
          id: 7,
          name: "Groceries",
          assigned: 400,
          activity: 300,
          available: 100,
        },
        {
          id: 8,
          name: "Eating Out",
          assigned: 150,
          activity: 125,
          available: 25,
        },
        { id: 9, name: "Car Fuel", assigned: 100, activity: 75, available: 25 },
      ],
    },
    {
      id: 4,
      name: "Non-Monthly",
      items: [
        {
          id: 10,
          name: "Car Insurance",
          assigned: 200,
          activity: 200,
          available: 0,
        },
      ],
    },
  ]);

  // Function to generate a new unique category id
  const generateNewCategoryId = () => {
    const maxId = budget.reduce(
      (max, category) => (category.id > max ? category.id : max),
      0
    );
    return maxId + 1;
  };

  // Function to add a new category
  const addCategory = (newCategory: Omit<Category, "id">) => {
    const categoryWithId = {
      id: generateNewCategoryId(),
      ...newCategory,
    };
    setBudget((prevBudget) => [...prevBudget, categoryWithId]);
  };

  // Function to add a new item to a category
  const addItem = (categoryId: number, newItem: Omit<Item, "id">) => {
    const generateNewItemId = () => {
      return (
        budget.reduce((max, category) => {
          const categoryMaxId = category.items.reduce(
            (maxItemId, item) => (item.id > maxItemId ? item.id : maxItemId),
            max
          );
          return categoryMaxId;
        }, 0) + 1
      );
    };

    const updatedBudget = budget.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          items: [...category.items, { id: generateNewItemId(), ...newItem }],
        };
      }
      return category;
    });

    setBudget(updatedBudget);
  };

  return (
    <BudgetContext.Provider value={{ budget, addCategory, addItem }}>
      {children}
    </BudgetContext.Provider>
  );
};
