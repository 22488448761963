import React from "react";
import "./BudgetHeader.scss";
import DateSelector from "../DateSelector/DateSelector";
import ToBeAssigned from "../ToBeAssigned/ToBeAssigned";

interface BudgetHeaderProps {}

const BudgetHeader: React.FC<BudgetHeaderProps> = ({}) => {
  return (
    <div className="budget-header flex justify-between items-center p-4 border-b dark:bg-gray-900 dark:border-gray-700">
      <div className="flex-1">
        <DateSelector onDateChange={() =>{}} />
      </div>

      <div className="flex-1 text-center">
        <ToBeAssigned amount={500} onAssign={() => {}} />
      </div>

      <div className="flex-1 text-center hidden md:block">&nbsp;</div>
    </div>
  );
};

export default BudgetHeader;
