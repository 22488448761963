import React from "react";

interface ButtonGroupProps {
  children: React.ReactNode;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ children }) => {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      {React.Children.map(children, (child, index) => (
        <div
          className={
            index === 0
              ? "rounded-l-md"
              : index === React.Children.count(children) - 1
              ? "rounded-r-md"
              : ""
          }
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default ButtonGroup;
