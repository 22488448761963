import React, { useState } from "react";

interface EditCateogryItemModalProps {
  item: { name: string; assigned: number; activity: number; available: number };
  onClose: () => void;
  onSave: (updatedItem: {
    name: string;
    assigned: number;
    activity: number;
    available: number;
  }) => void;
}

const EditCateogryItemModal: React.FC<EditCateogryItemModalProps> = ({
  item,
  onClose,
  onSave,
}) => {
  const [formState, setFormState] = useState(item);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-xl font-bold mb-4">Edit {item.name}</h2>
        <label className="block mb-2">Assigned:</label>
        <input
          type="number"
          name="assigned"
          value={formState.assigned}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 border rounded"
        />
        <label className="block mb-2">Activity:</label>
        <input
          type="number"
          name="activity"
          value={formState.activity}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 border rounded"
        />
        <label className="block mb-2">Available:</label>
        <input
          type="number"
          name="available"
          value={formState.available}
          onChange={handleInputChange}
          className="w-full p-2 mb-4 border rounded"
        />
        <div className="flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
            onClick={() => onSave(formState)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCateogryItemModal;
