import React from "react";
import "./Toolbar.scss";
import Button from "../Button/Button";
import { useBudget } from "../../contexts/BudgetContext";

interface ToolbarProps {}

const Toolbar: React.FC<ToolbarProps> = ({}) => {
  
  const { addCategory } = useBudget();

  const handleAddCategory = () => {
    const newCategory = {
      name: "New Category",
      items: [
      ],
    };

    addCategory(newCategory);
  };

  const undo = () => {};
  const redo = () => {};

  return (
    <div className="toolbar flex p-4">
      <div className="flex-shrink-0">
        <Button iconLeft="fal fa-plus" onClick={handleAddCategory} small={true}>
          Category
        </Button>
      </div>
      <div className="flex-shrink-0 mx-2">
        <Button
          iconLeft="fal fa-undo"
          variant="outlined"
          onClick={undo}
          disabled={true}
          small={true}
        >
          Undo
        </Button>
      </div>
      <div className="flex-shrink-0">
        <Button
          iconLeft="fal fa-redo"
          variant="outlined"
          onClick={redo}
          disabled={true}
          small={true}
        >
          Redo
        </Button>
      </div>
    </div>
  );
};

export default Toolbar;
